<template>
  <div class="container-fluid">
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Kundennummer suchen..."
            v-model="query.customerId"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Name suchen..."
            v-model="query.name"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Postleitzahl suchen..."
            v-model="query.zipcode"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Ort suchen..."
            v-model="query.city"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <select
            v-model="selectedWeightType"
            class="form-control form-control-sm"
          >
            <option :value="null">Stückelung...</option>
            <option
              :value="weight.type"
              v-for="weight in weights"
              :key="weight._id"
            >
              {{ weight.type }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            placeholder="Gesamtgewicht suchen..."
            v-model.number="totalWeight"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <FullCalendar
          :options="calendarOptions"
          ref="fullCalendar"
        ></FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { debounce } from 'lodash';
import { getBookings, getWeights } from '../../api';
import { min, max, getTime, addDays, parseISO } from 'date-fns';

export default {
  name: 'calendar',
  components: {
    FullCalendar,
  },
  async created() {
    this.weights = await getWeights();
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, bootstrapPlugin],
        initialView: 'dayGridDay',
        locale: 'de',
        firstDay: '1',
        weekNumbers: true,
        headerToolbar: {
          start: '',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay today prev,next',
        },
        buttonText: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
        },
        dayMaxEventRows: true,
        eventDisplay: 'block',
        views: {
          dayGridMonth: {
            dayMaxEventRows: 6,
          },
        },
        eventTextColor: 'white',
        displayEventTime: false,
        themeSystem: 'bootstrap',
        events: (info, successCb) => {
          const startTime = getTime(info.start.valueOf());
          const endTime = getTime(info.end.valueOf());
          const { name, city, zipcode, customerId } = this.query;

          getBookings({
            start: startTime,
            end: endTime,
            name,
            city,
            zipcode,
            customerId,
            withReservations: true,
          }).then((bookings) => {
            let events = bookings.map((booking) => {
              if (!booking.customer) {
                booking.customer = {
                  name: booking.customerLegacy,
                  customerId: '',
                  city: '',
                  zipcode: '',
                };
              }

              const weightsObj = booking.weights.reduce((prev, curr) => {
                if (curr.amount) {
                  prev[curr.type] = curr;
                  prev[curr.type].value = curr.value * curr.amount;
                }

                return prev;
              }, {});

              const piecesStr = [];

              Object.keys(weightsObj).forEach((key) => {
                piecesStr.push(
                  ' | ' +
                    weightsObj[key].type +
                    ' - ' +
                    weightsObj[key].value +
                    'kg'
                );
              });

              const weightsStr = piecesStr.reduce((prev, curr) => {
                prev += curr;

                return prev;
              }, '');

              const event = {
                title: `${booking.customer.customerId} - ${
                  booking.customer.name
                } - ${booking.customer.zipcode} - ${
                  booking.customer.city
                } ${weightsStr} ${
                  booking.isReservation
                    ? '- RESERVIERUNG - ' + booking.ticketNr
                    : ''
                }`,
                start: addDays(
                  min(booking.dates.map((date) => parseISO(date))),
                  1
                ),
                end: addDays(
                  max(booking.dates.map((date) => parseISO(date))),
                  1
                ),
                weights: booking.weights,
                backgroundColor: booking.isReservation ? 'green' : null,
              };

              return event;
            });

            if (this.totalWeight) {
              events = events.filter((event) => {
                const sum = event.weights.reduce((prev, curr) => {
                  if (curr.amount) {
                    prev += curr.value;
                  }

                  return prev;
                }, 0);

                return sum === this.totalWeight;
              });
            }

            if (this.selectedWeightType) {
              events = events.filter((event) => {
                return event.weights
                  .filter((weight) => weight.amount > 0)
                  .map((weight) => weight.type)
                  .includes(this.selectedWeightType);
              });
            }

            successCb(events);
          });
        },
      },
      selectedWeightType: null,
      query: {
        name: '',
        zipcode: '',
        city: '',
        customerId: '',
      },
      totalWeight: null,
      weights: [],
    };
  },
  watch: {
    query: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
      deep: true,
    },
    totalWeight: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
    },
    selectedWeightType: {
      handler: debounce(async function () {
        this.requesting = true;
        this.$refs.fullCalendar.getApi().refetchEvents();
        this.requesting = false;
      }, 500),
    },
  },
};
</script>